import { FC } from 'react';

import { Dispatch, Store } from 'redux';
import { QueryState } from '@redux/reducers/queryParams/types';
import { _isAuthorized } from '@redux/reducers/auth/selectors';
import { checkQueryParams } from '@redux/reducers/queryParams/action';
import {
  errorHandling,
  loadApplications,
} from '@redux/reducers/applications/action';
import { getCurrencies } from '@redux/reducers/currencies/actions';
import { getPurchasedInfo } from '@redux/reducers/profile/action';
import { getUserDetails } from '@redux/reducers/profile/action';
import { loadCountries } from '@redux/reducers/countries/action';
import { setInitialCountry } from '@redux/reducers/country/action';
import { setInitialCurrency } from '@redux/reducers/currency/action';
import { setInitialSession } from '@redux/reducers/auth/action';

import isServer from '@utils/isServer';

import Cookies from 'universal-cookie';
import { IncomingMessage } from 'http';
import { NextPageContext } from 'next';
import { Router } from 'next/router';
import affiliates from '@public/affiliates.json';
import { redirectToAffiliateLink } from '@shared/affiliates/functions';
/*
 * Creates the component type that allows you to use your own types for the "getInitialProps" method
 * P - props type
 * C - context: NextPageContext or extended type
 * T - return type
 */
export type NextFCWithInitialProps<
  P,
  C = InitialPropsContext,
  T = InitialProps
> = FC<P> & {
  getInitialProps: (context: C) => Promise<T>;
};

export type InitialProps = { isPromoNotFound: boolean; router?: Router };

type InitialPropsContext = NextPageContext & {
  req: Req;
  query: QueryState;
  store: Store;
};

type Req = IncomingMessage & {
  cookies: string;
  universalCookies: Cookies;
};

export function getInitialProps() {
  return async function ({
    req,
    res,
    query,
    store,
  }: InitialPropsContext): Promise<InitialProps> {
    const dispatch = store.dispatch as Dispatch<any>;

    const queryLanguage = query && query['lang'];

    const cookieLanguage =
      req && req.cookies && req.cookies['wp-wpml_current_language'];
    const acceptLanguage = req && req.headers && req.headers['accept-language'];

    const currentLanguage = queryLanguage || cookieLanguage || acceptLanguage;

    let isPromoNotFound = false;

    if (isServer()) {
      if (query.promo) {
        const affiliate = affiliates.find(
          affiliate => affiliate.code === query.promo
        );

        if (!affiliate) {
          isPromoNotFound = true;
        }
      }

      redirectToAffiliateLink(query, res);

      const cookies = req.universalCookies;

      const promises = [
        dispatch(setInitialSession(query, cookies)),
        dispatch(checkQueryParams(query)),
      ];
      await Promise.all(promises);

      const state = store.getState();
      const isAuthorized = _isAuthorized(state);

      try {
        await Promise.all([
          dispatch(loadCountries()),
          dispatch(loadApplications(currentLanguage)),
          dispatch(getCurrencies()),
        ]);
        if (isAuthorized) {
          await dispatch(getUserDetails());
          await dispatch(getPurchasedInfo());
        }
        // Run after getting all list of countries
        dispatch(setInitialCountry(cookies, query.reg));
        dispatch(setInitialCurrency(cookies, query.cur));
      } catch (error) {
        dispatch(errorHandling(error, res, cookies));

        return { isPromoNotFound };
      }
    }

    return { isPromoNotFound };
  };
}

export const getInitialPropsWithTranslation =
  namespacesRequired => async props => ({
    namespacesRequired,
    ...(await getInitialProps()(props)),
  });
